<template>
    <div>
        <div
            class="py-2 h-[50px] w-full border-gray-100 flex items-center mb-5"
        >
            <div v-if="isMobile" class="mr-5">
                <button
                    @click.prevent="
                        $emit('update:showProviders', true);
                        $emit('update:showProviderReceivers', false);
                        setCurrentBreadcrumbByProvider(null);
                    "
                >
                    <font-awesome-icon :icon="['fas', 'chevron-left']" />
                </button>
            </div>
            <div class="flex flex-1 px-2">
                <el-input
                    v-model="searchName"
                    class="flex-1 w-full max-w-full"
                    placeholder="搜尋名稱"
                    clearable
                    @keyup.enter.native="filterSearch"
                    @input="isEmptyName"
                ></el-input>
            </div>
        </div>
        <div>
            <ul
                id="chatUsers"
                ref="chatUsers"
                class="overflow-y-auto max-h-[500px] bg-white"
            >
                <li
                    v-for="(item, index) in users"
                    v-show="item.userData.banana_id !== serviceChatId"
                    :id="item.userData.banana_id"
                    :key="index"
                    class="cursor-pointer flex items-center px-5 py-2.5"
                    :class="
                        item.userData.banana_id === currentCityAiReceiverId
                            ? 'bg-yellow-100'
                            : 'bg-white'
                    "
                    @click="
                        changeRoom(item.userData.banana_id, item.userData.name)
                    "
                >
                    <Avatar
                        v-if="$isEmpty(item.userData.thumbnails)"
                        :backgroundImg="item.userData.avatar"
                    />
                    <Avatar
                        v-else
                        :backgroundImg="
                            item.userData.thumbnails.avatar['360x360']
                        "
                    />
                    <div class="flex-1 ml-3">
                        <!-- 只有待約會才會顯示時間 (顯示時間為 開始約會的時間倒數) -->
                        <div class="flex">
                            <h5 class="flex-1 font-medium">
                                {{ item.userData.name }}
                            </h5>
                            <span v-if="$Debug"
                                >-{{ item.userData.banana_id }}</span
                            >
                            <span
                                class="pt-1 text-xs font-light text-gray-300 OpenSansFont"
                                >{{
                                    -item.lastMsgAt | formatMonthAndDayTime
                                }}</span
                            >
                        </div>
                        <div class="flex items-center mt-1">
                            <!-- 最後一筆聊天室內容 -->
                            <p class="flex-1 text-xs text-gray-300">
                                {{ $subString(item.message, 30) }}
                            </p>
                            <!-- 未讀訊息數量 -->
                            <span
                                v-if="item.unReadMessageCount > 0"
                                class="bg-red-600 rounded-full w-5 h-5 text-xs text-white text-center pt-0.5"
                                >{{
                                    item.unReadMessageCount > 1000
                                        ? 999
                                        : item.unReadMessageCount
                                }}</span
                            >
                        </div>
                    </div>
                </li>
                <li
                    v-if="scrollDownLoading"
                    class="min-h-[50px] w-full text-center"
                >
                    <img
                        class="block w-10 h-10 mx-auto animate-spin"
                        src="/img/loading.svg"
                        alt=""
                    />
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
// 導入大頭照組件
import Avatar from "@/components/Avatar";
import { db } from "@/plugins/firebase";
// 使用 loadash 指定 key 排序方法
import { orderBy } from "lodash/collection";

export default {
    name: "FirestoreUsers",
    components: {
        Avatar,
    },
    computed: {
        ...mapState("userStore", ["user"]),
        ...mapState("cityAiStore", [
            "currentCityAiProviderId",
            "currentCityAiReceiverId",
        ]),
    },
    data() {
        return {
            // 聊天對象
            users: [],
            // 監聽變數
            setOnSnapshot: null,
            scrollDownLoading: false,
            // 搜尋暱稱
            searchName: null,
            // 分頁定位點
            paginationKey: 0,
            serviceChatId: process.env.VUE_APP_SERVICE_CHAT_ID,
        };
    },
    methods: {
        ...mapMutations("cityAiStore", [
            "setCurrentCityAiReceiverId",
            "setCurrentBreadcrumbByProvider",
            "setCurrentBreadcrumbByReceiver",
        ]),
        /**
         * 判斷清空搜尋內容重新搜尋聊天對象
         * @param { type String(字串) } val 搜尋名稱
         */
        async isEmptyName(val) {
            if (this.$isEmpty(val)) {
                await this.filterSearch();
            }
        },

        // 搜尋名稱方法
        async filterSearch() {
            // 判斷有輸入搜尋內容時觸發
            if (!this.$isEmpty(this.searchName)) {
                // 搜尋符合名稱列表
                await this.filterSearchChatUsers(this.currentCityAiProviderId);
                return;
            } else {
                // 如果清空搜尋內容時 要先把聊天對象給清空
                this.users = [];
                // 還原 pagination 預設定位 key
                this.paginationKey = 0;

                await this.getChatUsers(this.currentCityAiProviderId);
            }
        },
        /**
         * 搜尋聊天對象方法
         * @param { type String(字串) } userId 登入者 id
         */
        filterSearchChatUsers(userId) {
            // eslint-disable-next-line no-async-promise-executor
            return new Promise(async (resolve, reject) => {
                // 取得搜尋搜尋聊天對象 collection
                const getData = db()
                    .collection("chat_rooms")
                    .doc(userId)
                    .collection("users");
                try {
                    // 取得搜尋搜尋聊天對象資料
                    const getSearchUsers = await getData
                        .orderBy(`userData.name`)
                        .startAt(`${this.searchName}`)
                        .endAt(`${this.searchName}` + "\uf8ff")
                        .get();

                    // 先清空聊天對象
                    this.users = [];
                    // 如果沒有搜尋到資料時不往下執行
                    if (getSearchUsers.empty) {
                        return;
                    }
                    // 將搜尋取得對象塞入資料聊天對象資料
                    getSearchUsers.forEach((user) => {
                        this.$set(this.users, this.users.length, user.data());
                        // 改變排序方式以 以最後一筆聊天時間做排序
                        this.users = orderBy(
                            this.users,
                            ["lastMsgAt"],
                            ["asc"]
                        );
                    });
                    this.$nextTick(() => {
                        resolve();
                    });
                } catch (err) {
                    reject(err);
                }
            });
        },
        /**
         * 監聽聊天列表
         * @param { type String(字串) } userId 登入者 id
         */
        listenChatUsers(userId) {
            return new Promise((resolve, reject) => {
                this.setOnSnapshot = db()
                    .collection("chat_rooms")
                    .doc(userId)
                    .collection("users")
                    .limit(100)
                    .onSnapshot(
                        (users) => {
                            users.docChanges().forEach((change) => {
                                // 當有新增資料時會觸發
                                if (change.type === "added") {
                                    console.log("work add", change.doc.data());
                                    this.updateUserData(change.doc.data());
                                }
                                // 當有更新資料時會觸發
                                if (change.type === "modified") {
                                    console.log(
                                        "work update",
                                        change.doc.data()
                                    );
                                    this.updateUserData(change.doc.data());
                                }
                                // 當有刪除資料時會觸發
                                if (change.type === "removed") {
                                    // console.log("Removed user: ", change.doc.data());
                                }
                            });
                            resolve();
                        },
                        (error) => {
                            console.log(error);
                            reject();
                        }
                    );
            });
        },
        /**
         * 更新聊天對象資料
         */
        updateUserData(newData) {
            const index = this.users.findIndex((user) => {
                if (
                    newData.userData !== undefined &&
                    user.userData !== undefined
                ) {
                    return (
                        user.userData.banana_id === newData.userData.banana_id
                    );
                }
                return false;
            });
            if (index !== -1) {
                this.$set(this.users, index, newData);
                this.users = orderBy(this.users, ["lastMsgAt"], ["asc"]);
                this.users = this.users.filter(
                    (user) => user.userData !== undefined
                );
            } else {
                this.users.unshift(newData);
                // 改變排序方式以 以最後一筆聊天時間做排序
                this.users = orderBy(this.users, ["lastMsgAt"], ["asc"]);
                this.users = this.users.filter(
                    (user) => user.userData !== undefined
                );
            }
        },
        /**
         * 取得聊天對象列表
         * @param { type String(字串) } userId 登入者 id
         */
        async getChatUsers(userId) {
            // eslint-disable-next-line no-async-promise-executor
            return new Promise(async (resolve, reject) => {
                try {
                    // 聊天對象名單 collection
                    let users = db()
                        .collection("chat_rooms")
                        .doc(userId)
                        .collection("users")
                        .limit(15)
                        .orderBy("lastMsgAt", "asc");
                    // 判斷分頁定位 key 非 0 時 啟用分頁定位方式
                    if (this.paginationKey !== 0) {
                        // 用分頁 key 定位目前位置 再往後面撈 15筆
                        users = await users
                            .startAfter(this.paginationKey)
                            .get();
                    } else {
                        // 預設第一次先不定位分頁 key 直接取最面的15筆
                        users = await users.get();
                    }
                    // 將分頁定位點改變成 聊天對象 最後一筆資料（firestore 是依據最後一筆資料當定位點)
                    this.paginationKey = users.docs[users.docs.length - 1];
                    if (users.empty) {
                        this.paginationKey = "end";
                        resolve();
                        return;
                    }
                    users = users.docs.filter((user) => {
                        return user.data().userData !== undefined;
                    });
                    users.forEach((user) => {
                        // 取得目前資料重複 index
                        const index = this.users.findIndex((filterUser) => {
                            return (
                                filterUser.userData.banana_id ===
                                user.data().userData.banana_id
                            );
                        });
                        // 判斷目前沒有重複資料才執行
                        if (index === -1) {
                            // 過濾掉客服聊天對象
                            if (
                                user.data().userData.banana_id !==
                                process.env.VUE_APP_SERVICE_CHAT_ID
                            ) {
                                // this.$set(this.users, this.users.length, user.data());
                            }
                        }
                    });
                    resolve();
                } catch (err) {
                    console.log(err);
                    reject(err);
                }
            });
        },

        /**
         * 設定聊天室資料
         * @param { type String(字串) } userId 使用者 id
         * @param { type String(字串) } userName 使用者名稱
         */
        async changeRoom(userId, userName) {
            // 判斷有選擇過服務商聊天對象時才觸發
            if (this.currentCityAiReceiverId !== null) {
                // 清空聊天室聊天內容
                this.$emit(
                    "onDisconnectMessages",
                    this.currentCityAiProviderId,
                    this.currentCityAiReceiverId
                );
            }
            // 設定選中服務商聊天對象 id
            this.setCurrentCityAiReceiverId(userId);
            // 設定選中服務商聊天對象 來顯示麵包屑
            this.setCurrentBreadcrumbByReceiver(userName);
            // 清空聊天室聊天內容
            this.$emit("onClearMessages");
            // 重新取得對應聊天室聊天內容
            this.$emit("onGetMessages", this.currentCityAiProviderId, userId);
            // 取消監聽服務商聊天對象列表
            this.$emit("onDisconnectReceiverList");
            // 取消監聽聊天對象資料
            this.$emit("onDisconnectReceiverUserInfo");
            // 監聽聊天對象資料
            this.$emit("onListenerReceiverUserInfo");
            // 取消讀取歷史訊息事件
            this.$emit("update:isReadHistory", false);
            // 監聽客服的聊天對象
            this.listenChatUsers(userId);
        },

        /**
         * 分頁機制往下滾到底時觸發
         */
        async scrollDownGetPagination() {
            this.scrollDownLoading = true;
            try {
                await this.getChatUsers(this.currentCityAiProviderId);
                this.scrollDownLoading = false;
                this.$nextTick(async () => {
                    const dom = this.$refs.chatUsers;
                    dom.scrollTo({ top: dom.scrollTop });
                });
            } catch (err) {
                console.log(err);
                this.scrollDownLoading = false;
            }
        },
        // 觸發視窗滾動事件
        windowSrcoll() {
            this.$nextTick(() => {
                if (this.$refs.chatUsers !== undefined) {
                    this.$refs.chatUsers.addEventListener(
                        "scroll",
                        this.getMoreData
                    );
                }
            });
        },
        // 取得分頁資料
        getMoreData() {
            // 抓取元素
            const dom = this.$refs.chatUsers;
            // 判斷當 滾動距離 加上 當前 div 高度 >= 滾動條高度時 觸發滾動更新分頁
            const getDataStart =
                dom.scrollTop + dom.clientHeight >= dom.scrollHeight;
            // 觸發滾動更新分頁
            if (getDataStart && this.$isEmpty(this.searchName)) {
                // 判斷是最後一筆資料時 就停止載入分頁
                if (this.paginationKey === "end") {
                    this.scrollDownLoading = false;
                    console.log("is end");
                    return;
                }
                this.scrollDownGetPagination();
                return;
            }
        },
        // 取消聊天列表監聽
        disconnect() {
            if (this.setOnSnapshot !== null) {
                // 取消監聽聊天對象
                this.setOnSnapshot();
            }
        },
    },
    watch: {
        async currentCityAiProviderId(val) {
            // 如果沒有選擇服務商時 須清空聊天對象列表
            this.users = [];

            if (val !== null) {
                if (this.setOnSnapshot !== null) {
                    // 取消監聽聊天對象
                    this.setOnSnapshot();
                }
                this.windowSrcoll();
                await this.getChatUsers(val);
                await this.listenChatUsers(val);
            } else {
                // 如果沒有選擇服務商時 還原 pagination 預設定位 key
                this.paginationKey = 0;
            }
        },
    },
    async created() {},
    beforeDestroy() {
        if (this.setOnSnapshot !== null) {
            // 取消監聽聊天對象
            this.setOnSnapshot();
        }
        this.$refs.chatUsers.removeEventListener("scroll", this.getMoreData);
    },
};
</script>
