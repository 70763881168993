<template>
    <div class="grid grid-cols-12 gap-4">
        <div v-if="currentBreadcrumb['provider'] !== null" class="col-span-12">
            <div class="inline-block">
                <ul class="flex p-2 bg-white rounded-lg">
                    <li
                        v-for="(breadcrumb, key) in currentBreadcrumb"
                        v-show="breadcrumb !== null"
                        :key="key"
                    >
                        {{ breadcrumb }}<span v-if="key == 'provider'">/</span>
                    </li>
                </ul>
            </div>
        </div>
        <div
            v-show="!isMobile || (isMobile && showProviders)"
            class="col-span-12 p-3 bg-white rounded-lg shadow-lg md:col-span-4"
        >
            <Providers
                ref="providers"
                :showProviders.sync="showProviders"
                :showProviderReceivers.sync="showProviderReceivers"
                @onClearMessages="clearMessages"
                @onDisconnectReceiverList="onShowProviderReceivers"
                @onDisconnectMessages="disconnectMessages"
            />
        </div>
        <div
            v-show="!isMobile || (isMobile && showProviderReceivers)"
            class="col-span-12 p-3 bg-white rounded-lg shadow-lg md:col-span-4"
        >
            <Receivers
                ref="receivers"
                :showProviders.sync="showProviders"
                :showProviderReceivers.sync="showProviderReceivers"
                @onClearMessages="clearMessages"
                @onGetMessages="getMessages"
                @onDisconnectReceiverList="onShowChats"
                @onDisconnectMessages="disconnectMessages"
                @onDisconnectReceiverUserInfo="disconnectUserInfo"
                @onListenerReceiverUserInfo="listenerUserInfo"
            />
        </div>
        <div
            v-show="!isMobile || (isMobile && showChats)"
            class="col-span-12 rounded-lg shadow-lg md:col-span-4 bg-gray-50"
        >
            <Messages
                v-if="currentCityAiReceiverId !== null"
                ref="messages"
                :showProviderReceivers.sync="showProviderReceivers"
                :showChats.sync="showChats"
                :isReadHistory.sync="isReadHistory"
            />
            <SendMessage
                v-if="currentCityAiReceiverId !== null"
                ref="sendMessage"
            />
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import Providers from "./components/FirestoreProviderChat.vue";
import Receivers from "./components/FirestoreReceiverByProvider.vue";
import Messages from "../components/FirestoreMessages.vue";
// 導入 發送聊天訊息組件
import SendMessage from "../components/FirestoreSendMessage.vue";
// 導入 firestore 連接資料庫方法
import { db } from "@/plugins/firebase";
export default {
    components: {
        Providers,
        Receivers,
        Messages,
        SendMessage,
    },
    computed: {
        ...mapState("cityAiStore", [
            "currentCityAiProviderId",
            "currentCityAiReceiverId",
            "currentBreadcrumb",
        ]),
    },
    data() {
        return {
            // 設定服務商的聊天對象監聽
            setReceiverSnapShot: null,
            // 判斷是否閱讀歷史訊息 (主要用意是判斷是否將聊天視窗滾到底)
            isReadHistory: false,
            // 判斷是否顯示 服務商聊天對象
            showProviderReceivers: false,
            // 判斷是否顯示 服務商列表
            showProviders: true,
            // 判斷是否顯示 聊天內容
            showChats: false,
        };
    },
    methods: {
        ...mapMutations("cityAiStore", [
            "setCurrentCityAiProviderId",
            "setCurrentCityAiReceiverId",
            "setCurrentCityAiReceiverUserInfo",
        ]),
        // 監聽聊天對象資料
        listenerUserInfo() {
            this.setReceiverSnapShot = db()
                .doc(
                    `chat_rooms/${this.currentCityAiProviderId}/users/${this.currentCityAiReceiverId}`
                )
                .onSnapshot(
                    async (snapshot) => {
                        // 未取得聊天對象資料時 將聊天對象資料設定為預設值 且不往下執行
                        if (!snapshot.exists) {
                            this.setCurrentCityAiReceiverUserInfo({
                                userData: {},
                            });
                            return;
                        }

                        this.setCurrentCityAiReceiverUserInfo(snapshot.data());
                    },
                    (error) => {
                        console.log(error);
                    }
                );
        },
        // 取消監聽服務商聊天對象
        disconnectUserInfo() {
            if (this.setReceiverSnapShot !== null) {
                this.setReceiverSnapShot();
            }
        },
        /**
         * 重新取得聊天室訊息
         * @param { type String(字串) } userId 使用者 id
         * @param { type String(字串) } receiveUserId 聊天對象 id
         */
        getMessages(userId, receiveUserId) {
            this.$nextTick(() => {
                if (this.$refs.messages !== undefined) {
                    this.$refs.messages.getMessages(userId, receiveUserId);
                }
            });
        },
        // 清空聊天室訊息
        clearMessages() {
            this.$nextTick(() => {
                if (this.$refs.messages !== undefined) {
                    this.$refs.messages.clearMessages();
                }
            });
        },
        /**
         * 取消監聽聊天訊息
         * @param { type String(字串) } userId 使用者 id
         * @param { type String(字串) } receiveUserId 聊天對像 id
         */
        disconnectMessages(userId, receiveUserId) {
            this.$nextTick(() => {
                if (this.$refs.messages !== undefined) {
                    this.$refs.messages.disconnect(userId, receiveUserId);
                }
            });
        },
        // 取消監聽服務商聊天對象列表
        async disconnectReceiverList() {
            this.$nextTick(() => {
                if (this.$refs.receivers !== undefined) {
                    this.$refs.receivers.disconnect();
                }
            });
        },
        /**
         * 顯示服務商聊天對象
         */
        onShowProviderReceivers() {
            this.disconnectReceiverList();
            this.showProviderReceivers = true;
            this.showProviders = false;
        },
        /**
         * 顯示聊天室內容
         */
        onShowChats() {
            this.disconnectReceiverList();
            this.showProviderReceivers = false;
            this.showChats = true;
        },
    },
    beforeDestroy() {
        if (this.setReceiverSnapShot !== null) {
            // 取消服務商聊天對象監聽
            this.setReceiverSnapShot();
        }
        this.setCurrentCityAiProviderId(null);
        this.setCurrentCityAiReceiverId(null);
    },
};
</script>
