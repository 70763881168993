<template>
    <div class="min-h-[500px]">
        <div
            class="py-2 h-[50px] flex items-center w-full border-gray-100 mb-10"
        >
            <div class="flex flex-1 px-2">
                <el-input
                    v-model="searchName"
                    class="flex-1 w-full max-w-full"
                    placeholder="搜尋名稱"
                    clearable
                    @keyup.enter.native="filterSearch"
                    @input="isEmptyName"
                ></el-input>
            </div>
        </div>
        <div>
            <ul
                id="providers"
                ref="chatUsers"
                class="overflow-y-auto max-h-[500px] bg-white"
            >
                <li
                    v-for="(item, index) in users"
                    :id="item.userData.banana_id"
                    :key="index"
                    class="cursor-pointer flex items-center px-5 py-2.5"
                    :class="
                        item.userData.banana_id === currentCityAiProviderId
                            ? 'bg-yellow-100'
                            : 'bg-white'
                    "
                    @click="
                        changeRoom(item.userData.banana_id, item.userData.name)
                    "
                >
                    <Avatar
                        v-if="$isEmpty(item.userData.thumbnails)"
                        :isLogin="item.online"
                        :backgroundImg="item.userData.avatar"
                    />
                    <Avatar
                        v-else
                        :isLogin="item.online"
                        :backgroundImg="
                            item.userData.thumbnails.avatar['360x360']
                        "
                    />
                    <div class="flex items-center flex-1 ml-3">
                        <span class="flex-1">
                            {{ item.userData.name }}
                            <span v-if="$Debug"
                                >-{{ item.userData.banana_id }}</span
                            >
                            <div class="flex items-center">
                                <div
                                    v-if="item.userData.role === 2"
                                    class="px-3 mr-2 text-xs text-white bg-red-500 rounded-full"
                                >
                                    未上架
                                </div>
                                <div
                                    v-if="item.enableCityAi"
                                    class="p-1 text-white bg-blue-800 rounded-full"
                                >
                                    <font-awesome-icon
                                        class="text-lg"
                                        :icon="['fas', 'robot']"
                                    />
                                </div>
                            </div>
                            <div class="text-xs text-gray-300">
                                {{ item.updatedAt | formatDateTime }}
                            </div>
                        </span>

                        {{ item.unReadMessageCount }}
                        <span
                            v-if="item.unReadMessageCount > 0"
                            class="w-[10px] h-[10px] bg-red-500 rounded-full"
                        ></span>
                    </div>
                </li>
                <li
                    v-if="scrollDownLoading"
                    class="min-h-[50px] w-full text-center"
                >
                    <img
                        class="block w-10 h-10 mx-auto animate-spin"
                        src="/img/loading.svg"
                        alt=""
                    />
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
// 導入大頭照組件
import Avatar from "@/components/Avatar";
// 導入 firestore 連接資料庫機制
import { db } from "@/plugins/firebase";
// 使用 loadash 指定 key 排序方法
import { orderBy } from "lodash/collection";

export default {
    components: {
        Avatar,
    },
    computed: {
        ...mapState("cityAiStore", [
            "currentCityAiProviderId",
            "currentCityAiReceiverId",
        ]),
    },
    data() {
        return {
            // 監聽變數
            setOnSnapshot: null,
            // 判斷是否為系統客服
            serviceChatId: process.env.VUE_APP_SERVICE_CHAT_ID,
            // 聊天對象
            users: [],
            // 搜尋聊天對象
            searchName: "",
            scrollDownLoading: false,
            // 分頁定位點
            paginationKey: 0,
        };
    },
    methods: {
        ...mapMutations("cityAiStore", [
            "setCurrentCityAiProviderId",
            "setCurrentCityAiReceiverId",
            "setCurrentBreadcrumbByProvider",
        ]),

        /**
         * 判斷清空搜尋內容重新搜尋聊天對象
         * @param { type String(字串) } val 搜尋名稱
         */
        async isEmptyName(val) {
            if (this.$isEmpty(val)) {
                await this.filterSearch();
            }
        },
        // 搜尋名稱方法
        async filterSearch() {
            // 判斷有輸入搜尋內容時觸發
            if (!this.$isEmpty(this.searchName)) {
                // 搜尋符合名稱列表
                await this.filterSearchChatUsers();
                return;
            } else {
                // 如果清空搜尋內容時 要先把聊天對象給清空
                this.users = [];
                // 還原 pagination 預設定位 key
                this.paginationKey = 0;
                await this.getChatUsers();
            }
        },
        /**
         * 搜尋聊天對象方法
         */
        filterSearchChatUsers() {
            // eslint-disable-next-line no-async-promise-executor
            return new Promise(async (resolve, reject) => {
                // 取得搜尋搜尋聊天對象 collection
                const getData = db().collection("chat_rooms");
                try {
                    // 取得搜尋搜尋聊天對象資料
                    const getSearchUsers = await getData
                        .where("userData.role", "in", [1, 2])
                        .orderBy(`userData.name`)
                        .startAt(`${this.searchName}`)
                        .endAt(`${this.searchName}` + "\uf8ff")
                        .get();

                    // 先清空聊天對象
                    this.users = [];
                    // 如果沒有搜尋到資料時不往下執行
                    if (getSearchUsers.empty) {
                        // 取消監聽服務商聊天對象列表
                        this.$emit("onDisconnectReceiverList");
                        // 設定服務商聊天對象 id 為 null（因為切換服務商時 須清空原本該服務商聊天對象）
                        this.setCurrentCityAiReceiverId(null);
                        // 設定選擇服務商 id 用來取得此服務商聊天對象
                        this.setCurrentCityAiProviderId(null);
                        // 清空聊天室聊天內容
                        this.$emit("onClearMessages");
                        // 取消讀取歷史訊息事件
                        this.$emit("update:isReadHistory", false);
                        return;
                    }
                    // 將搜尋取得對象塞入資料聊天對象資料
                    getSearchUsers.forEach((user) => {
                        this.$set(this.users, this.users.length, user.data());
                        // 改變排序方式以 以最後一筆聊天時間做排序
                        this.users = orderBy(
                            this.users,
                            ["updatedAt"],
                            ["desc"]
                        );
                    });
                    this.$nextTick(() => {
                        resolve();
                    });
                } catch (err) {
                    reject(err);
                }
            });
        },
        /**
         * 監聽服務商聊天列表
         */
        listenChatUsers() {
            return new Promise((resolve, reject) => {
                this.setOnSnapshot = db()
                    .collection("chat_rooms")
                    .where(`userData.role`, "in", [1, 2])
                    .orderBy("updatedAt", "desc")
                    .onSnapshot(
                        (users) => {
                            users.docChanges().forEach((change) => {
                                // 當有新增資料時會觸發
                                if (change.type === "added") {
                                    if (!this.$isEmpty(this.searchName)) {
                                        return;
                                    }

                                    this.updateUserData(change.doc.data());
                                }
                                // 當有更新資料時會觸發
                                if (change.type === "modified") {
                                    if (!this.$isEmpty(this.searchName)) {
                                        return;
                                    }
                                    this.updateUserData(change.doc.data());
                                }
                                // 當有刪除資料時會觸發
                                if (change.type === "removed") {
                                    // console.log("Removed user: ", change.doc.data());
                                }
                            });
                            resolve();
                        },
                        (error) => {
                            console.log(error);
                            reject();
                        }
                    );
            });
        },
        /**
         * 更新聊天對象資料
         */
        updateUserData(newData) {
            const index = this.users.findIndex(
                (user) => user.userData.banana_id === newData.userData.banana_id
            );
            if (index !== -1) {
                this.$set(this.users, index, newData);
                this.users = orderBy(this.users, ["updatedAt"], ["desc"]);
            } else {
                this.users.unshift(newData);
                // 改變排序方式以 以最後一筆聊天時間做排序
                this.users = orderBy(this.users, ["updatedAt"], ["desc"]);
            }
        },
        /**
         * 更換聊天室
         * @param { type String(字串) } userId 服務商id
         * @param { type String(字串) } userName 服務商名稱
         */
        async changeRoom(userId, userName) {
            // 判斷有選擇過服務商聊天對象時才觸發
            if (this.currentCityAiReceiverId !== null) {
                // 清空聊天室聊天內容
                this.$emit(
                    "onDisconnectMessages",
                    this.currentCityAiProviderId,
                    this.currentCityAiReceiverId
                );
            }
            // 取消監聽服務商聊天對象列表
            this.$emit("onDisconnectReceiverList");
            // 設定服務商聊天對象 id 為 null（因為切換服務商時 須清空原本該服務商聊天對象）
            this.setCurrentCityAiReceiverId(null);
            // 設定選擇服務商 id 用來取得此服務商聊天對象
            this.setCurrentCityAiProviderId(userId);
            // 設定選擇服務商名稱 來顯示麵包屑
            this.setCurrentBreadcrumbByProvider(userName);
            // 清空聊天室聊天內容
            this.$emit("onClearMessages");
            // 取消讀取歷史訊息事件
            this.$emit("update:isReadHistory", false);
        },

        /**
         * 取得服務商列表
         */
        async getChatUsers() {
            // eslint-disable-next-line no-async-promise-executor
            return new Promise(async (resolve, reject) => {
                try {
                    // 取得所有服務商人員
                    let users = db()
                        .collection("chat_rooms")
                        .where(`userData.role`, "in", [1, 2])
                        .orderBy("updatedAt", "desc")
                        .limit(15);
                    // 判斷分頁定位 key 非 0 時 啟用分頁定位方式
                    if (this.paginationKey !== 0) {
                        // 用分頁 key 定位目前位置 再往後面撈 15筆
                        users = await users
                            .startAfter(this.paginationKey)
                            .get();
                    } else {
                        // 預設第一次先不定位分頁 key 直接取最面的15筆
                        users = await users.get();
                    }
                    // 將分頁定位點改變成 聊天對象 最後一筆資料（firestore 是依據最後一筆資料當定位點)
                    this.paginationKey = users.docs[users.docs.length - 1];
                    if (users.empty) {
                        this.paginationKey = "end";
                        resolve();
                        return;
                    }
                    users.forEach((user) => {
                        // 取得目前資料重複 index
                        const index = this.users.findIndex(
                            (filterUser) =>
                                filterUser.userData.banana_id ===
                                user.data().userData.banana_id
                        );
                        // 判斷目前沒有重複資料才執行
                        if (index === -1) {
                            this.$set(
                                this.users,
                                this.users.length,
                                user.data()
                            );
                        }
                    });
                    // 改變排序方式以 以最後一筆聊天時間做排序
                    resolve();
                } catch (err) {
                    console.log(err);
                    reject(err);
                }
            });
        },

        // 取得分頁資料
        getMoreData() {
            // 抓取元素
            const dom = this.$refs.chatUsers;
            // 判斷當 滾動距離 加上 當前 div 高度 >= 滾動條高度時 觸發滾動更新分頁
            const getDataStart =
                dom.scrollTop + dom.clientHeight >= dom.scrollHeight;
            // 觸發滾動更新分頁
            if (getDataStart && this.$isEmpty(this.searchName)) {
                // 判斷是最後一筆資料時 就停止載入分頁
                if (this.paginationKey === "end") {
                    this.scrollDownLoading = false;
                    console.log("is end");
                    return;
                }
                this.scrollDownGetPagination();
                return;
            }
        },
        /**
         * 分頁機制往下滾到底時觸發
         */
        async scrollDownGetPagination() {
            this.scrollDownLoading = true;
            try {
                await this.getChatUsers();
                this.scrollDownLoading = false;
                this.$nextTick(async () => {
                    const dom = this.$refs.chatUsers;
                    dom.scrollTo({ top: dom.scrollTop });
                });
            } catch (err) {
                console.log(err);
                this.scrollDownLoading = false;
            }
        },
        // 觸發視窗滾動事件
        windowSrcoll() {
            this.$nextTick(() => {
                this.$refs.chatUsers.addEventListener(
                    "scroll",
                    this.getMoreData
                );
            });
        },
    },
    async created() {
        this.windowSrcoll();
        // 取得服務商列表
        await this.getChatUsers();
        console.log(this.users);
        // 監聽服務商列表
        await this.listenChatUsers();
    },
    beforeDestroy() {
        if (this.setOnSnapshot !== null) {
            // 取消監聽聊天對象
            this.setOnSnapshot();
        }
        this.$refs.chatUsers.removeEventListener("scroll", this.getMoreData);
    },
};
</script>
